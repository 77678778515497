import './App.css';

function AboutMe() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>About me</h1>
        <p> I am currently an undergraduate student studying Physics and Computer Science (AI) at UNSW.</p>
      </header>
    </div>
  );
}

export default AboutMe;
