import React from "react";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';


const ScrollHandler = props => {
  const trigger = useScrollTrigger({
    disableHysteresis: false,
    threshold: 0,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
  {React.cloneElement(props.children, {
    style: {
      backgroundColor: trigger ? "rgba(41, 41, 41, 0.95)" : "rgba(41, 41, 41, 0.95)",
      color: trigger ? "white" : "white",
    //   transition: trigger ? "0.3s" : "0.5s",
      boxShadow: "none",
      padding: "0px 0px",
      direction: 'down'
    }
  })}
  </Slide>
  );
};

const Navbar = props => {
  return (
    <ScrollHandler {...props}>
        {props.children}
    </ScrollHandler>
  );
};

export default Navbar;
