import { useState, useEffect } from "react";
import './TypingAnimation.css';


export default function TypingAnimation({textArray, speed}) {
    // typing animation that takes in a string and displays it one character at a time
    const [text, setText] = useState("");
    const [loopNum, setLoopNum] = useState(0);
    const [action, setAction] = useState("type"); // "type" or "pause" or "delete"
    const [typeSpeed, setTypeSpeed] = useState(speed);
    const toRotate = textArray;
    const switchSpeed = speed;
    const [postText, setPostText] = useState("|");
    // const [pauseCounter, setpauseCounter] = useState(0)
    var pauseCount = 0;

    // useEffect(() => {
    //     console.log(postText)
    //   }, [postText]);

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, typeSpeed);

    return () => {
      clearInterval(ticker);

    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = fullText;
    if (action==="type") {
        updatedText = fullText.substring(0, text.length + 1);
        if(updatedText === fullText) {
            setAction("pause");
            setTypeSpeed(switchSpeed);
        }
    } else if (action === "delete") {
        updatedText = fullText.substring(0, text.length - 1)
        setTypeSpeed(switchSpeed / 4);
    } else { // pause
        if (pauseCount <= 24) {
            
            if (pauseCount % 8 <= 3) {
                setPostText("|");
                console.log(postText)

            } else {
                setPostText("");
                console.log(postText)

            };
            // setpauseCounter(pauseCounter + 1);
            pauseCount = pauseCount + 1;
            // updatedText = (fullText.substring(0, text.length)).concat(postText);
            
        } else {
            setAction("delete");
            setPostText("|");
            // setpauseCounter(0);
            pauseCount = 0;
            updatedText = fullText.substring(0, text.length-1)
            
        }
        
    }
    
    setText(updatedText);

    if (action === "delete" && updatedText === "") {
        setAction("type");
      setLoopNum(loopNum + 1);
      setTypeSpeed(switchSpeed);
    }
  };

  return (
    <div>
    <span className="Main-Text">{text}
    
    </span>
    {((action === 'delete' || action === 'type') || (action === 'pause' && postText === '|')) ? <span className="Post-Text">|</span> : <span className="Post-Text-Invis">|</span>}

    </div>
    );
}