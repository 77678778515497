import logo from './logo.svg';
import './App.css';
import AboutMe from './AboutMe';
import Home from './Home';
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { deepPurple } from '@mui/material/colors';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { alpha } from "@mui/material";
import Navbar from './components/Navbar';
import AppBar from '@mui/material/AppBar';
import pvLogo from "./media/logo512.png";

const theme = createTheme({
  palette: {
    primary: deepPurple,
    secondary: {
      main: '#4caf50',
    },
  },
});

function App() {

  return (
    
    <BrowserRouter>

    <ThemeProvider theme={theme}>
    <Navbar >
      <AppBar>
        <Toolbar className="tool-bar">
          <IconButton
            className="Home-Icon"
            size="large"
            edge="start"
            aria-label="PV"
            color="primary"
            component={Link}
            to="/"
            rel="noopener noreferrer">
              <img src={pvLogo} alt="PV" style={{width: 'auto', height: '50px'}}/>
          
            
          </IconButton>
          
          <span className="btn-group">
            <Button color="inherit" component={Link} to="/about">
              About Me
            </Button>
            {/* <Button color="inherit"  href="https://github.com/PhillipVas" target="_blank" rel="noopener noreferrer">
              GITHUB
            </Button> */}
            
          </span>
          
            
          
          
        </Toolbar>
      </AppBar>
    </Navbar>
      
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/about" element={<AboutMe />}></Route>
        </Routes>
      
    </ThemeProvider>
    
    </BrowserRouter>
    
  );
}

export default App;
