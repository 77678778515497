import './App.css';
import TypingAnimation from './TypingAnimation';
import background from "./media/HomeScreenBackground.jpg";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { alpha } from "@mui/material";
import Navbar from './components/Navbar';
import GitHubLogo from "./media/GitHub-Logo/GitHub-Mark-Light-64px.png";
import LinkedInLogo from "./media/LinkedIn-Logo/LinkedInLogoWhite.png";

function Home() {
  const textArray = ["Data Science.", "Machine Learning.", "Physics."]
  return (
    <div className="App">
    {/* <AppBar position='fixed' className="App-Bar" style={{ backgroundColor: 'rgba(41, 41, 41, 0.8)' }}> */}
    
    {/* </AppBar> */}
    <header className="App-header" style={{ backgroundImage: `url(${background})` }}>
        <h1>
          Phillip Vasilescu
          <TypingAnimation textArray={textArray} speed={100}/>
        </h1>
        <p></p>
        <span>
        <IconButton
            className="GitHub-Icon"
            size="large"
            edge="start"
            color="primary"
            href="https://github.com/PhillipVas"
            target="_blank"
            rel="noopener noreferrer">
              <img src={GitHubLogo} alt="GitHub" style={{width: 'auto', height: '50px'}}/>
            </IconButton>
            <IconButton
            className="LinkedIn-Icon"
            size="small"
            edge="start"
            color="primary"
            href="https://www.linkedin.com/in/phillip-vasilescu/"
            target="_blank"
            rel="noopener noreferrer">
              <img src={LinkedInLogo} alt="LinkedIn"  style={{width: 'auto', height: '50px'}}/>
            </IconButton>
        </span>
      </header>
    <h1>As seen on Nathan's CV!</h1>
    <iframe src="https://nathan-ngo.com/" style={{width: '80vw', height: '100vh'}} title="Check it out!"></iframe>
    </div>
  );
}

export default Home;
